import axios from 'axios'
import { API_URL, JPA_API_URL } from '../../Constants'
//import UploadFilesService from '../../upload-files.service'


class TodoDataService {

    retrieveAllTodos(name) {
        //console.log('executed service')
        return axios.get(`${JPA_API_URL}/users/${name}/todos`);
    }


    retrieveTodo(name, id) {
        console.log('execute service, retrieveTodo:', name, id)
        // var todo = axios.get(`${JPA_API_URL}/users/${name}/todos/${id}`); //lcctest
        // console.log('retrievetodo, retrieved todo:', todo) //lcctest
        return axios.get(`${JPA_API_URL}/users/${name}/todos/${id}`);
    }


    deleteTodo(name, id) {
        //console.log('execute service delete todo')
        return axios.delete(`${JPA_API_URL}/users/${name}/todos/${id}`);
    }



    updateTodo(name, id, todo) {
        console.log('updatetodo: execute service, updateTodo:', name, id)
        return axios.put(`${JPA_API_URL}/users/${name}/todos/${id}`, todo);
    }

    createTodo(name, todo) {
        console.log('createtodo: execute service createTodo for name', name)
        console.log('createtodo: execute service createTodo for todo', todo)
        ///jpa/users/{username}/todos
        //return axios.post(`${JPA_API_URL}/users/${name}/todos/`, todo);
        return axios.post(`${JPA_API_URL}/users/${name}/todos`, todo);
    }


    /*
    *
    *  Documents services
    * 
    */
    retrieveAllDocuments(estimateId) {
        console.log('Executing retriveveAllDocuments for estimate:', estimateId)
        return axios.get(`${JPA_API_URL}/estimate/${estimateId}/documents`);
    }

    retrieveDocument(documentId) {
        console.log('execute service: retrieveDocument:', documentId)
        return axios.get(`${JPA_API_URL}/estimatedocuments/${documentId}`);
    }

    deleteDocument(documentId) { //lcctest new
        console.log('execute service deletedocument', documentId)
        return axios.delete(`${JPA_API_URL}/estimatedocuments/${documentId}`);
    }
    
    updateDocument(documentId, document) {
        console.log('----->>> execute service updatedocument', documentId)
        return axios.put(`${JPA_API_URL}/estimatedocuments/${documentId}`, document);
    }

    addDocument(estimateId, file, fileName, onUploadProgress) {
        console.log('todoDataService, will add Document with photo for estimateId', estimateId)
        console.log('todoDataService, will add Document with photo for filename', fileName)
        let formData = new FormData();
        formData.set('fileName', fileName)
        formData.append("file", file)
        return axios.post(`${JPA_API_URL}/estimatedocuments/${estimateId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
          });
    }

}

export default new TodoDataService()