import axios from 'axios'
import { HELLO_WORLD, HELLO_WORLD_BEAN, HELLO_WORLD_PATH } from '../../Constants';

class HelloWorldService {

    executeHelloWorldService() {
        //console.log('executed service')
        return axios.get(HELLO_WORLD);
    }

    executeHelloWorldBeanService() {
        //console.log('executed service')
        return axios.get(HELLO_WORLD_BEAN);
    }

    executeHelloWorldPathVariableService(name) {
        console.log('executed service executeHelloWorldPathVariableService for:', name)
        // let username = 'in28minutes'
        // let password = 'dummy'

        // let basicAuthHeader = 'Basic ' +  window.btoa(username + ":" + password)

        //HELLO_WORLD_PATH
        //return axios.get(HELLO_WORLD_PATH + `/${name}`
        return axios.get(HELLO_WORLD_PATH + `/${name}`
            // , 
            //     {
            //         headers : {
            //             authorization: basicAuthHeader
            //         }
            //     }
        );
    }

}

export default new HelloWorldService()