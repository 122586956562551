import React, { Component } from 'react'
import TodoDataService from '../../api/todo/TodoDataService.js'
import AuthenticationService from './AuthenticationService.js'
import Recaptcha from 'react-recaptcha';


class ListEstimateDocumentsComponent extends Component {
    constructor(props) {
        console.log('ListEstimateDocumentsComponent constructor')
        super(props)
        this.state = {
            estimateId: this.props.match.params.id,
            documents: [],
            message: null,
            isVerified: false
        }
        this.deleteDocumentClicked = this.deleteDocumentClicked.bind(this)       // delete the document
        this.updateDocumentClicked = this.updateDocumentClicked.bind(this)       // view (and update) the document (photo)
        this.addPhotoClicked = this.addPhotoClicked.bind(this)             // add a document and upload a photo
        this.refreshDocuments = this.refreshDocuments.bind(this)       
        this.cancelClicked = this.cancelClicked.bind(this)
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this)
        this.verifyCallback = this.verifyCallback.bind(this)
    }

    componentWillUnmount() {
        console.log('ListEstimateDocuments, componentWillUnmount')
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log('ListEstimateDocuments, shouldComponentUpdate')
        console.log(nextProps)
        console.log(nextState)
        return true
    }

    componentDidMount() {
        console.log('<<<>>>>>> ListEstimateDocuments, componentDidMount, will call refreshDocuments()')
        this.refreshDocuments();
        console.log(this.state)
    }

    refreshDocuments() {
        console.log('<<<>>>>>> ListEstimateDocuments, RefreshDocuments, estimateId:', this.state.estimateId)
        let estimateId = this.state.estimateId;
        TodoDataService.retrieveAllDocuments(estimateId)
            .then(
                response => {
                    console.log('<<<>>>>>> ListEstimateDocuments, Response from refreshDocuments: ', response);
                    this.setState({ documents: response.data })
                }
            )
    }

    deleteDocumentClicked(documentId, name) {
        console.log('deleteDocumentClicked() clicked') 
        //documentId = 'C62FBF9D-C498-4461-AF39-1325223EC834'
        TodoDataService.deleteDocument(documentId)
             .then(
                 response => {
                    // this.setState({ message: `Delete of image ${documentId} Successful` })
                    this.setState({ message: `Document:  ${name}  was deleted` })
                     this.refreshDocuments()
                 }
             )
    }


    recaptchaLoaded() {
        console.log('capcha succesfully loaded');
     }


     verifyCallback(response) {
        if (response) {
          this.setState({
            isVerified: true
          })
        } 
      }
        

    addPhotoClicked(id) { //navigate to the UploadPhotoComponent. Adding a photo creates a document.
        console.log('ListEstimateDocumentsComponent, addPhotoClicked() for id:', id)
        if (this.state.isVerified) {
            //alert('Thank you for verifying that you are not a robot!');
            this.props.history.push(`/documents/photos/${id}`)
          } else {
            alert('Please verify that you are not a robot');
        }
    }

    updateDocumentClicked(document) {
        console.log('ListEstimateDocumentsComponent, updateDocumentClicked for id:', document.id)
        console.log('--->>ListEstimateDocumentsComponent, will move to /documents/id, with id:', document.id)
        console.log('--->>ListEstimateDocumentsComponent, the estimateid is:', this.state.estimateId)
        //this.props.history.push(`/documents/${id}`)
        //let originalFileName = '1.jpg'
        let originalFileName = document.originalFileName
        this.props.history.push(`/estimates/${this.state.estimateId}/documents/${document.id}/photos/${originalFileName}`)
        //this.props.history.push(`/estimates/${this.state.estimateId}/photos/${originalFileName}`)
    }

    cancelClicked() {
        this.props.history.push("/todos")
    }

    render() {
        console.log('render ListEstimateDocumentsComponent')
        return (
            <div>
                <h3>My Estimate Photos</h3>
                <div><b>(upload up to 10 .jpg or .png image files. Max. size 5Mb each)</b></div>
                <div><label></label></div>
                {this.state.message && <div class="alert alert-success">{this.state.message}</div>}
                <div className="container">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>View</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.documents.map(
                                    document =>
                                        <tr key={document.id}>
                                            <td>{document.name}</td>
                                            <td><button className="btn btn-primary" onClick={() => this.updateDocumentClicked(document)}>View Photo</button></td>
                                            <td><button className="btn btn-danger" onClick={() => this.deleteDocumentClicked(document.id, document.name)}>Delete</button></td>
                                        </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <div className="row">
                        <button className="btn btn-success" onClick={() => this.addPhotoClicked(this.state.estimateId)}>Add Photo</button>
                            &nbsp;&nbsp;&nbsp;
                        <button className="btn btn-default" type="reset" onClick={() => this.cancelClicked()} >Cancel</button>
                    </div>

                    <div>
                        <label>    </label>
                    </div>

                    <div>
                        <Recaptcha
                            sitekey="6Lf1UA4aAAAAAHD2KEMEh5UFpgjPQhNiqqXjLyK4"
                            render="explicit"
                            onloadCallback={this.recaptchaLoaded}
                            verifyCallback={this.verifyCallback}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default ListEstimateDocumentsComponent
