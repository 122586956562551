import axios from 'axios'
import firebase from 'firebase'
import { API_URL } from '../../Constants'

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const USER_NAME_SESSION_ATTRIBUTE_DISPLAY_NAME = "authenticatedUserDisplayName"

class AuthenticationService {

    executeBasicAuthenticationService(username, password) {
        console.log('executeBasicAuthenticationService')
        return axios.get(`${API_URL}/basicauth`,
            { headers: { authorization: this.createBasicAuthToken(username, password) } })
    }

    executeJwtAuthenticationService(username, password) {
        console.log('executeJWtAuthenticationService')
        return axios.post(`${API_URL}/authenticate`, {
            username,
            password
        })
    }

    createBasicAuthToken(username, password) {
        console.log('creatingBasicAuthToken', username, password)
        return 'Basic ' + window.btoa(username + ":" + password)
    }

    // registerSuccessfulLogin(username, password) {
    //     //let basicAuthHeader = 'Basic ' +  window.btoa(username + ":" + password)
    //     console.log('Authenticationservice, registerSuccessfulLogin')
    //     sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
    //     this.setupAxiosInterceptors(this.createBasicAuthToken(username, password))
    // }

    registerSuccessfulLoginForJwt(username, token) {
        console.log('registering registerSuccessfulLoginForJwt', username, token)
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        this.setupAxiosInterceptors(this.createJWTToken(token))
    }

    createJWTToken(token) {
        console.log('creatingJwtToken', token)
        return 'Bearer ' + token
    }


    logout() {
        console.log("authservice, logout")
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
    }


    //lcctest new
    logoutFireBase() {
        console.log("authservice, logoutFireBase")
        firebase.auth().signOut()
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
    }

    //lcctest new
    loginFireBase(username, password, displayName) {
        console.log('Authenticationservice, loginFireBase')
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_DISPLAY_NAME, displayName)
        //this.setupAxiosInterceptors(this.createBasicAuthToken(username, password))
    }

    isUserLoggedIn() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

    getLoggedInUserDisplayName() {
        let userDisplayName = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_DISPLAY_NAME)
        if (userDisplayName === null) return ''
        return userDisplayName
    }

    setupAxiosInterceptors(token) {

        axios.interceptors.request.use(
            (config) => {
                if (this.isUserLoggedIn()) {
                    config.headers.authorization = token
                }
                return config
            }
        )
    }
}

export default new AuthenticationService()