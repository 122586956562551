// Production configuration
export const API_URL = 'https://www.catalog-on-line.com:8085/'
export const JPA_API_URL = 'https://www.catalog-on-line.com:8085/jpa'
export const HELLO_WORLD = 'https://www.catalog-on-line.com:8085/hello-world'
export const HELLO_WORLD_BEAN = 'https://www.catalog-on-line.com:8085/hello-world-bean'
export const HELLO_WORLD_PATH = 'https://www.catalog-on-line.com:8085/hello-world/path-variable'
export const PHOTO_PATH = 'https://www.catalog-on-line.com:8085/jpa/estimates/'

// Development configuration
// export const API_URL = 'http://localhost:8085'
// export const JPA_API_URL = 'http://localhost:8085/jpa'
// export const HELLO_WORLD = 'http://localhost:8085/hello-world'
// export const HELLO_WORLD_BEAN = 'http://localhost:8085/hello-world-bean'
// export const HELLO_WORLD_PATH = 'http://localhost:8085/hello-world/path-variable'
// export const PHOTO_PATH = 'http://localhost:8085/jpa/estimates/'
