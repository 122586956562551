import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AuthenticationService from './AuthenticationService.js'
import { withRouter } from 'react-router';


class HeaderComponent extends Component {
    render() {
        const isUserLoggedIn = AuthenticationService.isUserLoggedIn();
        //console.log(isUserLoggedIn);
        console.log('header component isUserLoggedIn:', isUserLoggedIn);

        return (
            <header>
                <nav className="navbar navbar-expand-md navbar-dark bg-dark">
                    <div><a href="http://www.LeijaDesigns.com" className="navbar-brand">Leija Designs Inc</a></div>
                    <ul className="navbar-nav">
                        {isUserLoggedIn && <li><Link className="nav-link" to="/welcome/estimates">Home</Link></li>}
                        {isUserLoggedIn && <li><Link className="nav-link" to="/todos">Estimates</Link></li>}
                    </ul>
                    <ul className="navbar-nav navbar-collapse justify-content-end">
                        {/* {!isUserLoggedIn && <li><Link className="nav-link" to="/login">Login</Link></li>} */}
                        {/* {isUserLoggedIn && <li><Link className="nav-link" to="/logout" onClick={() => AuthenticationService.logoutFireBase()}>Logout</Link></li>} */}
                        {isUserLoggedIn && <li><Link className="nav-link" to="/logout" onClick={AuthenticationService.logoutFireBase}>Logout</Link></li>}
                    </ul>
                </nav>
            </header>
        )
    }
}

export default withRouter(HeaderComponent);
