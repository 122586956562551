import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import HelloWorldService from '../../api/todo/HelloWorldService.js'

class WelcomeComponent extends Component {

    constructor(props) {
        super(props)

        console.log('welcome component, render, props:', this.props)

        this.retrieveWelcomeMessage = this.retrieveWelcomeMessage.bind(this)
        this.state = {
            welcomeMessage: '',
        }

        //console.log('welcome component, props', this.props)

        this.handleSuccessfulResponse = this.handleSuccessfulResponse.bind(this)
        this.handleError = this.handleError.bind(this)
    }

    render() {
        return (
            <>
                <h1>Welcome!</h1>
                <div className="container">
                    <b>Welcome {this.props.match.params.name}, Please request job estimate <Link to="/todos">here</Link>.</b>
                    {/* Welcome {this.props.history.location.state.displayName}  You can manage your Estimates <Link to="/todos">here</Link>. */}
                </div>
                <div><label> </label></div>
                {/* <div className="container">
                    Click here to see special offer of the day.
                </div> */}
                <div><label> </label></div>
                <div className="container">
                    <button onClick={this.retrieveWelcomeMessage} className="btn btn-success">Today's Special</button>
                </div>
                <div><label> </label></div>
                <div className="container">
                    {this.state.welcomeMessage}
                </div>

            </>
        )
    }

    retrieveWelcomeMessage() {
        HelloWorldService.executeHelloWorldPathVariableService(this.props.match.params.name)
            .then(response => this.handleSuccessfulResponse(response))
            .catch(error => this.handleError(error))
    }

    handleSuccessfulResponse(response) {
        console.log(response)
        this.setState({ welcomeMessage: response.data.message })
    }

    handleError(error) {

        console.log(error.response)

        let errorMessage = '';

        if (error.message)
            errorMessage += error.message

        if (error.response && error.response.data) {
            errorMessage += error.response.data.message
        }

        this.setState({ welcomeMessage: errorMessage })
    }

}

export default WelcomeComponent