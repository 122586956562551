import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import AuthenticatedRoute from './AuthenticatedRoute.jsx'
import LoginComponent from './LoginComponent.jsx'
import ListTodosComponent from './ListTodosComponent.jsx'
import ErrorComponent from './ErrorComponent.jsx'
import HeaderComponent from './HeaderComponent.jsx'
import FooterComponent from './FooterComponent.jsx'
import LogoutComponent from './LogoutComponent.jsx'
import WelcomeComponent from './WelcomeComponent.jsx'
import TodoComponent from './TodoComponent.jsx'
import ListEstimateDocumentsComponent from './ListEstimateDocumentsComponent.jsx'
import DocumentComponent from './DocumentComponent.jsx'
import PhotoUploadComponent from './PhotoUploadComponent.jsx'



class TodoApp extends Component {
    render() {
        return (
            <div className="TodoApp">
                <Router>
                    <>
                        <HeaderComponent/>
                        <Switch>
                            <Route path="/" exact component={LoginComponent}/>
                            <Route path="/login" component={LoginComponent}/>
                            <AuthenticatedRoute path="/welcome/:name" component={WelcomeComponent}/>
                            <AuthenticatedRoute path="/todos/:id" component={TodoComponent}/>
                            <AuthenticatedRoute path="/todos" component={ListTodosComponent}/>
                            <AuthenticatedRoute path="/documents/estimate/:id" component={ListEstimateDocumentsComponent}/>
                            <AuthenticatedRoute path="/documents/photos/:id" component={PhotoUploadComponent}/>
                            {/* <AuthenticatedRoute path="/documents/:id" component={DocumentComponent}/> */}
                            <AuthenticatedRoute path="/estimates/:estimateId/documents/:id/photos/:originalFileName" component={DocumentComponent}/>
                            {/* <AuthenticatedRoute path="/estimates/:estimateId/photos/:originalFileName" component={DocumentComponent}/> */}
                            <AuthenticatedRoute path="/logout" component={LogoutComponent}/>
                            
                            <Route component={ErrorComponent}/>
                        </Switch>
                        <FooterComponent/>
                    </>
                </Router>
                {/*<LoginComponent/>
                <WelcomeComponent/>*/}
            </div>
        )
    }
}

export default TodoApp