import React, { Component } from 'react'
import moment from 'moment'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TodoDataService from '../../api/todo/TodoDataService.js'
import AuthenticationService from './AuthenticationService.js'
import { isCompositeComponent } from 'react-dom/test-utils';


class TodoComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.match.params.id,
            description: '',
            requestDate: moment(new Date()).format('YYYY-MM-DD'),
            approved: false,
            status: '',
            customerName: AuthenticationService.getLoggedInUserDisplayName()
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)
        this.handleStatusChange = this.handleStatusChange.bind(this)
        this.cancelClicked = this.cancelClicked.bind(this)
    }

    componentDidMount() {

        if (this.state.id === -1) {
            return
        }

        let username = AuthenticationService.getLoggedInUserName()

        TodoDataService.retrieveTodo(username, this.state.id)
            .then(response => this.setState({
                description: response.data.description,
                requestDate: moment(response.data.requestDate).format('YYYY-MM-DD'),
                approved: response.data.approved,
                status: response.data.status,
                updateTs: response.data.updateTs,
                updatedBy: response.data.updatedBy,
                createTs: response.data.createTs,
                createdBy: response.data.createdBy,
                email: response.data.email,
                version: response.data.version,
                estimateNumber: response.data.estimateNumber,
                customerName: response.data.customerName
            }))
    }

    validate(values) {
        let errors = {}
        if (!values.description) {
            errors.description = 'Enter a Description'
        } else if (values.description.length < 5) {
            errors.description = 'Enter at least 5 Characters in Description'
        }

        if (!moment(values.requestDate).isValid()) {
            errors.requestDate = 'Enter a valid Target Date'
        }
        return errors
    }

    onSubmit(values) {
        let username = AuthenticationService.getLoggedInUserName()

        var createNew = false
        if (this.state.id == -1) {
          console.log("on submit, state.id = -1")
          createNew = true 
        } else {
            console.log("todocomponent update, on submit state.id = ", this.state.id)
            console.log("todocomponent update, on submit values.version = ", values.version)
        }

        // for update
        let todo = {
            id: this.state.id,
            username: AuthenticationService.getLoggedInUserName(),
            description: values.description,
            requestDate: values.requestDate,
            approved: values.approved,
            // status: values.status,
            status: this.state.status,
            updateTs: values.updateTs,
            updatedBy: values.updatedBy,
            createTs: values.createTs,
            createdBy: values.createdBy,
            email: AuthenticationService.getLoggedInUserName(),
            version: values.version,
            estimateNumber: this.state.estimateNumber,
            customerName: this.state.customerName
        }
    
        if (createNew) {
            todo = {
                id: null, //this.state.id,
                username: AuthenticationService.getLoggedInUserName(),
                description: values.description,
                requestDate: values.requestDate,
                version: 1,
                email: AuthenticationService.getLoggedInUserName(),
                customerName: this.state.customerName,
                approved: false
            }
        }
        
        console.log('todocomponent onsubmit, id:', this.state.id)

        //if (this.state.id == -1) {
        if (createNew) {
            console.log('** todocomponent, will createtodo')
            TodoDataService.createTodo(username, todo)
                .then(() => this.props.history.push('/todos'))
        } else {
            console.log('*** todocomponent will call updatetodo')
            TodoDataService.updateTodo(username, this.state.id, todo)
                .then(() => this.props.history.push('/todos'))
        }

        console.log('***** OnSubmit, TodoComponent values:', values);
        
    } //onSubmit

    cancelClicked() {
        this.props.history.push("/todos")
    }


    handleStatusChange(values) {
    }


    render() {
        let { description, requestDate, status, estimateNumber } = this.state

        // should the submit button be disabled?
        let sumEnabled = true
        var n = 1
        n = status.localeCompare('Converted')
        if (n < 0 || n > 0) {
            sumEnabled = false
        }

        return (
            <div>
                {/* <h1>Estimate:  {estimateNumber} </h1> */}
                <b>Estimate No. {estimateNumber} </b>
                <div className="instructions">
                    <p class="small">
                       Enter the description of the job(s) for which you would like to have a price estimate<br></br>
                       If you have more than one job please enter them separated by a '-' character<br></br>
                       Ex: Upholstery for Loveseat sofa - Curtain for main bedroom - Fix table legs<br></br>
                       Measurements are also helpful.<br></br>
                    </p>
                </div>
                <div className="container">
                    <Formik
                        initialValues={{ description, requestDate, status, estimateNumber }}
                        onSubmit={this.onSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={this.validate}
                        enableReinitialize={true}
                    >
                        {
                            (props) => (
                                <Form>
                                    <ErrorMessage name="description" component="div"
                                        className="alert alert-warning" />
                                    <ErrorMessage name="requestDate" component="div"
                                        className="alert alert-warning" />

                                    <fieldset className="form-group">
                                        <label>Status: </label> {this.state.status}
                                    </fieldset>

                                    {/* <fieldset className="form-group">
                                        <label>Estimate Number: </label> {this.state.estimateNumber}
                                    </fieldset> */}

                                    <fieldset className="form-group">
                                        <label>Description</label>
                                        <Field className="form-control" component="textarea" name="description" rows="5" 
                                           placeholder="Article 1 description
                                           - Article 2 description
                                           - Article 3 description
                                           - Article 4 description
                                           - Article 5 description"
                                        />                                        
                                    </fieldset>

                                    <fieldset className="form-group">
                                        <label>Request Date</label>
                                        <Field className="form-control" type="date" name="requestDate" />
                                    </fieldset>

                                    <button className="btn btn-success" type="submit" disabled={sumEnabled}>Save</button>
                                        &nbsp;&nbsp;&nbsp;
                                    <button className="btn btn-default" type="reset" onClick={() => this.cancelClicked()} >Cancel</button>
                                    <br/><br/>
                                    <div>
                                    <p className="small" id="convertedMessage" hidden={!sumEnabled}>
                                       Converted applications cannot be changed or deleted. Please contact Leija Designs if you wish to change or delete.
                                    </p>
                                    <br/>
                                    <br/>                                        
                                    </div>
                                    {/* <button className="btn btn-primary" type="submit" >Cancel</button> */}
                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </div>
        )
    }
}

export default TodoComponent