import React, { Component } from 'react';
//import logo from './logo.svg';
//import './App.css';
import firebase from 'firebase'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import AuthenticationService from './AuthenticationService.js'
import WelcomeComponent from './WelcomeComponent'

firebase.initializeApp({
    apiKey: "AIzaSyB7DaDQ7yn7QqtWKOZZ8Z46KIk9MaIlsp4",
    authDomain: "prospects-70ffa.firebaseapp.com"
  })
  
  
  class LoginComponent extends Component {

    constructor(props) {
      super(props)

      this.state = {
          username: '',
          password: '',
          hasLoginFailed: false,
          showSuccessMessage: false,
          isSignedIn : false,
          displayName: ''
      }

      this.loginClicked = this.loginClicked.bind(this)
  }

    //state={isSignedIn : false}
    uiConfig = {
      signInFlow : "popup",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccess: () => false
      }
    }
  
    componentDidMount = () => {
      firebase.auth().onAuthStateChanged(user => {
        this.setState({isSignedIn : !!user})
        console.log("User:", user)
      })
    }
  
      loginClicked() {
        AuthenticationService
            .executeJwtAuthenticationService(this.state.username, this.state.password)
            .then((response) => {
                AuthenticationService.registerSuccessfulLoginForJwt(this.state.username, response.data.token)
                //this.props.history.push(`/welcome/${this.state.username}`)
            }).catch(() => {
                //this.setState({ showSuccessMessage: false })
                //this.setState({ hasLoginFailed: true })
            })
      }    

    render() {
      const styleCtr = {textAlign: 'center'};
      const styleBottom = {bottom: '30px'};

      if (this.state.isSignedIn) {
        this.state = {
          username: firebase.auth().currentUser.email,
          displayName: firebase.auth().currentUser.displayName, 
          password: 'dummy',
          hasLoginFailed: false,
          isSignedIn : true
        }
         console.log('logincomponent, displayName:', firebase.auth().currentUser.displayName)
         AuthenticationService.loginFireBase(this.state.username, this.state.password, this.state.displayName)
         this.loginClicked()
      }

      return (
        <div className="App">
          {this.state.isSignedIn ? (

            this.props.history.push({
                                     pathname: `/welcome/${this.state.username}`,
                                     state: {isSignedIn : true, displayName: this.state.displayName}
            })

          ) : (
           <StyledFirebaseAuth
           uiConfig={this.uiConfig}
           firebaseAuth={firebase.auth()}
           />

          )}

          {/* <div style = {styleCtr, styleBottom}> */}
          <div className="privacy">
            <a href="TermsOfUse.html">Terms of Use</a>  &nbsp; <a href="PrivacyPolicy.html">Privacy Policy</a>
            <p>Use of this site means acceptance of the Terms of Use and Privacy Policy</p>
          </div>

        </div>
      )
    }
  }
  export default LoginComponent;