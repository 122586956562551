import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TodoDataService from '../../api/todo/TodoDataService.js'
import { PHOTO_PATH } from '../../Constants'
//import PhotoContainer from './PhotoContainer.js';



class DocumentComponent extends Component {
    constructor(props) {
        super(props)

        
        this.state = {
            documentId: this.props.match.params.id,
            estimateId: this.props.match.params.estimateId,
            originalFileName: this.props.match.params.originalFileName,
            name: '',
            photos: [],
            // photoPath: 'http://localhost:8085/jpa/estimates/' + this.props.match.params.estimateId + '/photos/' + this.props.match.params.originalFileName + '?auto=compress&cs=tinysrgb&h=350'
            photoPath: PHOTO_PATH + this.props.match.params.estimateId + '/photos/' + this.props.match.params.originalFileName + '?auto=compress&cs=tinysrgb&h=350'

        }
        //console.log('====>>>DocumentComponent, constructor, state:', this.state)

        this.onSubmit = this.onSubmit.bind(this)
        this.validate = this.validate.bind(this)
        this.handleStatusChange = this.handleStatusChange.bind(this)
        this.cancelClicked = this.cancelClicked.bind(this)

    }

    // componentWillMount() {
    //     // console.log('====>>>DocumentComponent, componentWillMount, state:', this.state)
    //     // let documentId = this.state.documentId
    //     // TodoDataService.retrieveDocument(documentId)
    //     //     .then(response => 
    //     //         //console.log('====>>>After retrieve document, response:', response) //lcctest only
    //     //         this.setState({
    //     //             createTs: response.data.createTs,
    //     //             createdBy: response.data.createdBy,
    //     //             updateTs: response.data.updateTs,
    //     //             updatedBy: response.data.updatedBy,
    //     //             version: response.data.version,
    //     //             name: response.data.name,
    //     //             originalFileName: response.data.originalFileName,
    //     //             estimateId: response.data.estimateId
    //     //         })
    //     //     );
    // }

    componentDidMount() {
        console.log('====>>>DocumentComponent, componentDidMount, state:', this.state)
        let documentId = this.state.documentId
        TodoDataService.retrieveDocument(documentId)
            .then(response => 
                //console.log('====>>>After retrieve document, response:', response) //lcctest only
                 this.setState({
                //     createTs: response.data.createTs,
                //     createdBy: response.data.createdBy,
                //     updateTs: response.data.updateTs,
                //     updatedBy: response.data.updatedBy,
                //     version: response.data.version,
                     name: response.data.name,
                //     originalFileName: response.data.originalFileName,
                //     estimateId: response.data.estimateId,
                //     photoPath: 'http://localhost:8085/jpa/estimates/' + response.data.estimateId + '/photos/' + this.state.originalFileName + '?auto=compress&cs=tinysrgb&h=350'
                 })
            );
        console.log('====>>>DocumentComponent, componentDidMount, after response, state:', this.state)
    }

    validate(values) {
        let errors = {}
        if (!values.photoName) {
            errors.photoName = 'Please enter a name for the photo'
        } else if (values.photoName.length < 5) {
            errors.photoName = 'Enter at least 5 Characters for the photo name'
        }
        return errors
    }


    onSubmit(values) {
        console.log('**** DocumentComponent, onsubmit, id:', this.state.id)
        console.log('**** DocumentComponent, onsubmit, originalFileName:', this.state.originalFileName)
        console.log(values);

        // for update
        let document = {
            id: this.state.documentId,
            name: values.photoName,
            createTs:  this.state.createTs,
            createdBy: this.state.createdBy,
            updateTs: this.state.updateTs,
            updatedBy: this.state.updatedBy,
            version: this.state.version,
            originalFileName: this.state.originalFileName,
            //estimateId: 'C62FBF9D-C498-4461-AF39-1325223EC834'
            estimateId: this.state.estimateId
        }

        let documentId = this.state.documentId

        console.log('***>>> documentComponent will call updatetodo, state.estimateId:', this.state.estimateId)
        console.log('***>>> documentComponent will call updatetodo, document', document)
        TodoDataService.updateDocument(documentId, document)
            //.then(() => this.props.history.push('/documents/estimate/C62FBF9D-C498-4461-AF39-1325223EC834'))
            .then(() => this.props.history.push(`/documents/estimate/${this.state.estimateId}`))

    }

    
    handleStatusChange(values) {
    }

    cancelClicked() {
        //this.props.history.push("/todos")
        this.props.history.push(`/documents/estimate/${this.state.estimateId}`)
    }


    render() {
        let photoName  = this.state.name
        let estimateId = this.state.estimateId

        console.log('> DocumentComponent, render, state.name:', this.state.name)
        console.log('> DocumentComponent, render, photoname:', photoName)
        console.log('> DocumentComponent, render, originalFileName:', this.state.originalFileName)
        console.log('> DocumentComponent, render, estimateId:', estimateId)
        //let photoPath = 'http://localhost:8085/jpa/estimates/c62fbf9d-c498-4461-af39-1325223ec834/photos/' + this.state.originalFileName + '?auto=compress&cs=tinysrgb&h=350'
        // let photoPath = 'http://localhost:8085/jpa/estimates/' + estimateId + '/photos/' + this.state.originalFileName + '?auto=compress&cs=tinysrgb&h=350'
        let photoPath = this.state.photoPath
        console.log('> DocumentComponent, render, photoPath:', photoPath)

        return (
            <div>
                <h1>Photo</h1>
                <div className="container">
                    <Formik
                        initialValues={{ photoName }}
                        onSubmit={this.onSubmit}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validate={this.validate}
                        enableReinitialize={true}
                    >
                        {
                            (props) => (
                                <Form>
                                    <ErrorMessage name="photoName" component="div"
                                        className="alert alert-warning" />

                                    <fieldset className="form-group">
                                        <label>Name</label>
                                        <Field className="form-control" type="text" name="photoName" />
                                    </fieldset>

                                        <button className="btn btn-success" type="submit" onSubmit={() => this.onSubmit()}>Save</button>
                                        &nbsp;&nbsp;&nbsp;                                           
                                        <button className="btn btn-default" type="reset" onClick={() => this.cancelClicked()} >Cancel</button>

                                    <div> - </div>

                                    {/* <div id="photo">Photo here</div> */}
                                    {/* <PhotoContainer photos={this.state.photos} /> */}
                                    <div>
                                        <img 
                                           src={`${photoPath}`}
                                           alt="new"
                                       />
                                    </div>

                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </div>
        )
    }
}

export default DocumentComponent