import React from 'react';
import { render } from 'react-dom';
import { Formik, Field } from "formik";
import * as yup from "yup";
import ThumbComponent from './ThumbComponent';
import TodoDataService from '../../api/todo/TodoDataService';


class PhotoUploadComponent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
        estimateId: this.props.match.params.id,
        progress: 0,
        message: "",
        fileInfos: []
    }
    this.cancelClicked = this.cancelClicked.bind(this)
    this.validate = this.validate.bind(this)
    console.log('PhotoUploadComponent constructor, for estimate id:', this.state.estimateId)
  }

  cancelClicked() {
    //this.props.history.push("/todos")
    this.props.history.push(`/documents/estimate/${this.state.estimateId}`)
  }

  validate(values) {
    let errors = {}
    if (!values.photoName) {
        errors.description = 'Enter a photo name'
    }
    return errors
  }


  render() {
    return (
      <div className="container">
        <Formik 
          initialValues={{ file: null }}
          validate={this.validate}

          onSubmit={(values) => {
            console.log('**** PhotoUploadComponent, onSubmit', values)
            // let document = {
            //   estimateId: this.state.estimateId,
            //   username: AuthenticationService.getLoggedInUserName(),
            //   photoName: values.name,
            //   version: values.version
            // }
            
            // alert(
            //   JSON.stringify(
            //     { 
            //       estimateId: this.state.estimateId,
            //       fileName: values.file.name, 
            //       type: values.file.type,
            //       size: `${values.file.size} bytes`
            //     },
            //     null,
            //     2
            //   )
            // );
             
            console.log('**** PhotoUploadComponent, will call dataservice with estimateid', this.state.estimateId)
            TodoDataService.addDocument(this.state.estimateId, values.file, values.photoName,  (event) => {
              this.setState({
                progress: Math.round((100 * event.loaded) / event.total),
              });
            })
//                .then(() => this.props.history.push('/todos'))
                .then(() => this.props.history.push(`/documents/estimate/${this.state.estimateId}`))
                .catch(() => {
                  alert('could not upload the file. Please check maximum file size and file type .jpg or png only')
                  this.setState({
                    progress: 0,
                    message: "Could not upload the photo!",
                    currentFile: undefined,
                  })
                }) //catch

          }} //onSubmit

          validationSchema={yup.object().shape({
            file: yup.mixed().required(),
          })}
          
          render={
            ({ values, handleSubmit, setFieldValue, progress }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label for="file">Image upload</label>

                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-info progress-bar-striped"
                      role="progressbar"
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: progress + "%" }} >
                      {progress}%
                    </div>
                  </div>

                  <fieldset className="form-group">
                    <label>Name</label>
                    <Field className="form-control" type="text" id="photoName" name="photoName" placeholder = "Name your photo here"/>
                  </fieldset>

                  <label htmlFor="file" style={{ background:"green", color:"white", padding:"5px 10px" }}>Select Photo</label>
                  <input id="file" name="file" type="file" style={{visibility:"hidden"}} onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }} className="form-control" />

                  <div>
                    <button className="btn btn-default" type="reset" onClick={() => this.cancelClicked()} >Cancel</button>
                    &nbsp;&nbsp;
                    <button type="submit" className="btn btn-primary">Upload Selected Photo</button><br></br>
                    Please tap 'Upload Selected' one time and wait until the window is closed<br></br>                    
                  </div>

                  <ThumbComponent file={values.file} />

                </div>

              </form>
              
            );

          }} />  

      </div>

    );
  }
};

  
export default PhotoUploadComponent
